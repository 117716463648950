/* The below google font is overwriting my fonts in main comopnent, hence commented out */
/* @import url('https://fonts.googleapis.com/css?family=Dosis:300,400,500'); */



@-moz-keyframes glow-star {
    40% {
        -moz-opacity: 0.3;
    }

    90%,
    100% {
        -moz-opacity: 1;
        -moz-transform: scale(1.2);
    }
}

@-webkit-keyframes glow-star {
    40% {
        -webkit-opacity: 0.3;
    }

    90%,
    100% {
        -webkit-opacity: 1;
        -webkit-transform: scale(1.2);
    }
}

@keyframes glow-star {
    40% {
        -webkit-opacity: 0.3;
        opacity: 0.3;
    }

    90%,
    100% {
        -webkit-opacity: 1;
        opacity: 1;
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        border-radius: 999999px;
    }
}


html,
body {
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: 'Dosis', sans-serif;
    font-weight: 300;
    -webkit-user-select: none;
    /* Safari 3.1+ */
    -moz-user-select: none;
    /* Firefox 2+ */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    /* Standard syntax */
}

.bg-purple {
    background: url("../../assets/images/bg_purple.png");
    background-repeat: repeat-x;
    background-size: cover;
    background-position: left top;
    height: 100vh;
    overflow: hidden;

}

/* --------------------------- */


.custom-navbar {
    padding-top: 15px;
}

.brand-logo {
    margin-left: 25px;
    margin-top: 5px;
    display: inline-block;
}

.navbar-links {
    display: inline-block;
    float: right;
    margin-right: 15px;
    text-transform: uppercase;


}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    align-items: center;
}

li {

    padding: 0px 15px;
}

li a {
    display: block;
    color: white;
    text-align: center;
    text-decoration: none;
    letter-spacing: 2px;
    font-size: 12px;

    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

li a:hover {
    color: #ffcb39;
}

/* clear */
.btn-request {
    padding: 10px 25px;
    border: 1px solid #FFCB39;
    border-radius: 100px;
    font-weight: 400;
}

.btn-request:hover {
    background-color: #FFCB39;
    color: #fff;
    transform: scale(1.05);
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
}

.btn-go-home {
    position: relative;
    z-index: 200;
    margin: 15px auto;
    width: 100px;
    padding: 10px 15px;
    border: 1px solid #FFCB39;
    border-radius: 100px;
    font-weight: 400;
    display: block;
    color: white;
    text-align: center;
    text-decoration: none;
    letter-spacing: 2px;
    font-size: 11px;

    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.btn-go-home:hover {
    background-color: #FFCB39;
    color: #fff;
    transform: scale(1.05);
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
}

/* clear */
.central-body {
    width: 100%;
    padding: 17% 5% 10% 5%;
    text-align: center;
}

.objects img {
    z-index: 90;
    pointer-events: none;
}

/* ============================= */


.image-404 {
    position: relative;
    z-index: 100;
    pointer-events: none;
}

.stars {
    background: url("../../assets/images/overlay_stars.svg");
    background-repeat: repeat;
    background-size: contain;
    background-position: left top;
}

.glowing_stars .star {
    position: absolute;
    border-radius: 100%;
    background-color: #fff;
    width: 3px;
    height: 3px;
    opacity: 0.3;
    will-change: opacity;
}

.glowing_stars .star:nth-child(1) {
    top: 80%;
    left: 25%;
    animation: glow-star 2s infinite ease-in-out alternate 1s;
}

.glowing_stars .star:nth-child(2) {
    top: 20%;
    left: 40%;
    animation: glow-star 2s infinite ease-in-out alternate 3s;
}

.glowing_stars .star:nth-child(3) {
    top: 25%;
    left: 25%;
    animation: glow-star 2s infinite ease-in-out alternate 5s;
}

.glowing_stars .star:nth-child(4) {
    top: 75%;
    left: 80%;
    animation: glow-star 2s infinite ease-in-out alternate 7s;
}

.glowing_stars .star:nth-child(5) {
    top: 90%;
    left: 50%;
    animation: glow-star 2s infinite ease-in-out alternate 9s;
}

@media only screen and (max-width: 600px) {
    .navbar-links {
        display: none;
    }

    .custom-navbar {
        text-align: center;
    }

    .brand-logo img {
        width: 120px;
    }


    .central-body {
        padding-top: 25%;
    }
}